import React, { useEffect, useContext, useState } from "react";
import Helmet from "react-helmet";
import styled from "styled-components";
import { Link } from "gatsby";
import { Row, Col } from "styled-bootstrap-grid";

// Components
import { FlexContainer } from "../components/containers/flex-container";
import { AspectRatioImageContainer } from "../components/containers/aspect-ratio-image-container";
import { SearchPageProducts } from "../components/search/search-page-products";
import { Spacer } from "../components/utils/spacer";

// Filters
import { secondaryDark, tertiary } from "../components/utils/colors";

// Context
import { FooterColor } from "../components/context/footer-color";

const Page = styled.div`
	margin: 100px 0 0 0;

	@media (max-width: 768px) {
		margin: 65px 0 0 0;
	}

	& h1 {
		margin: 0 0 20px 0;

		font-size: 22px;
		line-height: 125%;
		letter-spacing: 0;

		@media (max-width: 1024px) {
			font-size: 20px;
		}
	}
`;

const NoResults = styled.div`
	& p {
		margin: 0;
	}

	& a {
		color: ${secondaryDark};
    border-bottom: 1px solid ${secondaryDark};
}

		&:hover {
			color: ${secondaryDark};
		}
	}
`;

const Search = ({ location }) => {
	const [searchResults, updateSearchResults] = useState([]);
	const [footerColor, setFooterColor] = useContext(FooterColor);

	useEffect(() => {
		if (
			location !== null &&
			location.state !== null &&
			location.state !== undefined
		) {
			updateSearchResults(location.state.searchResults);
		} else {
			return null;
		}
	}, [location]);

	useEffect(() => {
		setFooterColor(tertiary);
	}, []);

	if (
		location !== null &&
		location.state !== null &&
		location.state !== undefined
	) {
		return (
			<>
				<Page>
					<FlexContainer
						desktopPadding={`0 60px`}
						tabletPadding={`0 35px`}
						mobilePadding={`0 15px`}
						cv={true}
					>
						{location !== null &&
						location.state !== null &&
						location.state !== undefined ? (
							<>
								<Helmet
									title={`Search Results for '${location.state.searchQuery}' | Christian Watson`}
								/>

								<Row>
									<Col col={12}>
										<h1>Search Results for '{location.state.searchQuery}'</h1>
									</Col>
								</Row>
								<Row>
									{searchResults !== null &&
										searchResults !== undefined &&
										searchResults.map((product, outerIndex) => (
											<Col
												col={6}
												md={4}
												lg={3}
												key={`single_related_product_${product.id}_${outerIndex}`}
											>
												<Link to={`/products/${product.handle}`}>
													<div className="product">
														<AspectRatioImageContainer
															image={null}
															padding={75}
														>
															{product.product.images[0].originalSrc !==
																undefined && (
																<img
																	src={product.product.images[0].originalSrc}
																	alt={product.product.title}
																	loading={`lazy`}
																/>
															)}
														</AspectRatioImageContainer>
														<p>{product.product.title}</p>
													</div>
												</Link>
											</Col>
										))}
									{location.state.searchResults.length === 0 && (
										<Col col={12}>
											<NoResults>
												<p>
													We are sorry, but no products matched your search.
												</p>
												<p>
													Please try a different search or explore our{" "}
													<Link to={`/furniture/signature`}>furniture</Link>.
												</p>
											</NoResults>

											<SearchPageProducts />
										</Col>
									)}
								</Row>
							</>
						) : (
							<Row>
								<Col col={12}>
									<h1>Search Results</h1>
								</Col>
							</Row>
						)}
					</FlexContainer>
				</Page>
			</>
		);
	} else {
		return (
			<>
				<Helmet
					title={`Search Results | Christian Watson`}
					meta={[
						{
							name: "og:title",
							content: `Search Results | Christian Watson`,
						},
					]}
				/>
				<Page>
					<FlexContainer
						desktopPadding={`0 60px`}
						tabletPadding={`0 35px`}
						mobilePadding={`0 15px`}
						cv={true}
					>
						<Row>
							<Col col={12}>
								<h1>Search Results</h1>
							</Col>
							<Col col={12}>
								<NoResults>
									<p> We are sorry, but no products matched your search.</p>
									<p>
										Please try a different search or{" "}
										<Link to={`/shop`}>explore our latest collection.</Link>
									</p>
								</NoResults>
							</Col>
						</Row>
					</FlexContainer>
				</Page>
				<Spacer />
			</>
		);
	}
};

export default Search;
