import React, { useState, useEffect } from "react";
import { useStaticQuery, graphql } from "gatsby";
import styled from "styled-components";
import { useFlexSearch } from "react-use-flexsearch";
import { navigate } from "gatsby";

// Styles
import { secondaryDark } from "../utils/colors";

const SearchContainer = styled.div`
	width: 100%;

	margin: 60px 0;
	padding: 0;

	@media (max-width: 960px) {
		width: 100%;
		max-width: 100%;

		margin: 0;
		padding: ${(props) => props.padding};

		& button {
			display: flex;
			flex-direction: row;
			flex-wrap: nowrap;
			justify-content: space-between;
			align-items: center;

			width: 100%;
			line-height: 50px;
		}
	}
`;

const SearchForm = styled.form`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;

	max-width: 378px;
	width: 100%;

	@media (max-width: 500px) {
		max-width: 100%;
	}

	border-bottom: 1px solid ${secondaryDark};

	& input {
		padding: 0 10px 0 0;
		margin: 0;

		border: 0;

		width: calc(100% - 25px);
		background-color: transparent;
	}

	& button[type="submit"] {
		margin: 0;
	}

	& .search-trigger {
		margin: 0 0 3px 0;
		display: block;

		font-size: 20px;
	}

	& button {
		margin: 0;
		cursor: pointer;
		width: auto;

		&:hover {
			stroke: ${secondaryDark};
		}
	}
`;

export const SearchPageProducts = ({ location, isMobileSearch, fillColor }) => {
	const data = useStaticQuery(graphql`
		{
			localSearchProducts {
				index
				store
			}
		}
	`);

	const [isSearchOpen, setSearchOpen] = useState(false);
	const [query, setQuery] = useState("");
	const results = useFlexSearch(
		query,
		data.localSearchProducts.index,
		JSON.parse(data.localSearchProducts.store)
	);

	const onEscape = (event) => {
		if (event.keyCode === 27 && isSearchOpen === true) {
			setSearchOpen(false);
		}
	};

	const navigateToSearchResultsPage = (event) => {
		event.preventDefault();
		setSearchOpen(false);
		navigate(`/search/?q=${query}`, {
			state: {
				searchResults: results,
				searchQuery: query,
			},
		});
	};

	useEffect(() => {
		if (typeof window !== undefined && isSearchOpen === true) {
			document.getElementById("search-input").focus();
			document.addEventListener("keydown", (event) => onEscape(event));
		}
	}, [isSearchOpen]);

	useEffect(() => {
		setSearchOpen(false);
		setQuery("");
	}, [location]);

	return (
		<SearchContainer>
			<SearchForm onSubmit={navigateToSearchResultsPage}>
				<input
					id="search-input"
					name="query"
					type="text"
					value={query}
					onChange={(e) => setQuery(e.target.value)}
				/>

				<button type="submit">
					<span className="search-trigger">→</span>
				</button>
			</SearchForm>
		</SearchContainer>
	);
};
